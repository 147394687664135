@import "var";

/* You can add global styles to this file, and also import other style files */
body {
  font-family: "Noto Sans Bengali", sans-serif;
  padding: 0 !important;
  background-color: whitesmoke;
  color: #323031;
}

ul {
  list-style: none;
  padding-inline-start: 0;
}

a {
  text-decoration: none;
  color: $purple;
}

a:hover {
  color: $purple-dark;
}

.success-text {
  color: $green;
}

.error-text {
  color: $red;
}

.big-font-icon {
  font-size: 1.5rem;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.content {
  padding-left: 1rem;
  padding-right: 1rem;
}

.content-card {
  // border: 1px solid rgba($color: #000000, $alpha: 0.1);
  border: $border;
  background-color: white;
  overflow-x: auto;
  margin-bottom: 1rem;
  min-width: 15rem;
}

.section-info {
  padding: 1rem;
  background-color: rgba($color: $blue, $alpha: 0.1);
}

.field,
.editor-field {
  display: flex;
  padding: 0.5rem 1rem;
}

.field-label {
  width: 250px;
}

.field-value {
  text-align: left;
  display: flex;
  flex: 1;
}

.mat-card-content {
  flex: 1; /* Allow content to expand and fill the available space */
}

.dynamic-flex {
  display: flex;
  flex-direction: row;
}

.flex-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.mat-expansion-panel {
  border: 1px solid rgba($color: #000000, $alpha: 0.1);
  background-color: white;
  overflow-x: auto;
  box-shadow: none !important;
}

.mat-accordion > .mat-expansion-panel {
  margin-bottom: 0.5rem;
}

.mat-expansion-panel-body {
  // border: 1px solid rgba($color: #000000, $alpha: 0.1);
  padding: 0 0.5rem 0.5rem !important;
}

.tab-content-wrapper {
  margin: 1rem 0;
}

mat-card.today {
  background-color: $purple-bg !important;
  // color: white;
  //border-color: $purple-dark;
}

.editor-field mat-form-field {
  width: 100%;
}

.mat-mdc-header-cell {
  color: $purple;
  background-color: rgba($color: #000000, $alpha: 0.03) !important;
}

.btn-edit:enabled {
  background-color: rgba($color: $purple, $alpha: 0.9) !important;
  color: white !important;
}

.btn-detail:enabled {
  background-color: rgba($color: $blue, $alpha: 0.9) !important;
  color: white !important;
}

.btn-save:enabled,
.btn-create:enabled {
  background-color: rgba($color: $green, $alpha: 0.9) !important;
  color: white !important;
}

.btn-delete:enabled,
.btn-cancel:enabled,
.btn-discard:enabled {
  background-color: rgba($color: $red, $alpha: 1) !important;
  color: white !important;
}

.edit-panel {
  margin-bottom: 0.5rem;
  // border-bottom: 1px solid gray;
  display: flex;
}

@media screen and (max-width: 600px) {
  .dynamic-flex {
    flex-direction: column;
  }

  .editor-field {
    flex-direction: column;
  }

  .flex-content::after {
    content: none; /* Remove pseudo-element for mobile */
  }
}
